import { useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ProfilePage } from '@Components';
import { PathParams } from '@App/paths';
import { useRouter } from '@Helpers';
import * as topLoader from '@Helpers/topLoader';
import { useUserContext } from '@Contexts';
import {
  usePublicFunderProfile,
  useGetFundsList,
  useGetFundedProjects,
  useGetFunderProjectUpdates,
} from '@Hooks/Api';
import { FundingProfileData, FundStatusEnum } from '@Types';
import router from 'next/router';
import { useGetCurrentTab } from '@Hooks/useGetCurrentTab';

interface FunderProfilePageProps {
  initialFunderProfileData: FundingProfileData;
}

export const FunderProfilePageController = ({
  initialFunderProfileData,
}: FunderProfilePageProps) => {
  const { params } = useRouter();
  const organizationId = Number(params[PathParams.ORGANIZATION_ID]);
  const [getFunderProfileData, { data }] = usePublicFunderProfile();
  const [getFundsList, { paginatedData: fundsListPaginatedResponse }] = useGetFundsList();
  const {
    page: fundsList,
    loading: isFundsListLoading,
    totalCount: fundsListTotalCount,
  } = fundsListPaginatedResponse;
  const [getFundedProjects, { paginatedData: fundedProjectsPaginatedData }] =
    useGetFundedProjects();
  const { loading: isFundedProjectsLoading, totalCount: totalProjectsCount } =
    fundedProjectsPaginatedData;
  const [getProjectUpdates, { paginatedData: projectUpdatesPaginatedData }] =
    useGetFunderProjectUpdates();
  const { loading: isProjectUpdatesLoading, totalCount: totalProjectUpdatesCount } =
    projectUpdatesPaginatedData;
  const { t } = useTranslation('profilePage');
  const { userData } = useUserContext();

  useEffect(() => {
    getFundsList(organizationId, [
      FundStatusEnum.LIVE,
      FundStatusEnum.CLOSED,
      FundStatusEnum.COMPLETE,
      FundStatusEnum.IN_DELIVERY,
    ]);
  }, [getFundsList, organizationId]);

  useEffect(() => {
    if (userData?.user_type) {
      topLoader.start();
      getFunderProfileData(organizationId).finally(() => {
        topLoader.done();
      });
    }
  }, [getFunderProfileData, organizationId, userData?.user_type]);

  useEffect(() => {
    getFundedProjects({ organizationId });
  }, [getFundedProjects, organizationId]);

  useEffect(() => {
    getProjectUpdates(organizationId);
  }, [getProjectUpdates, organizationId]);

  const tabs = useMemo(
    () => [
      { title: t('group_page_tabs.about'), slug: 'about' },

      {
        title: t('group_page_tabs.funds', {
          fundsCount: isFundsListLoading ? '...' : fundsListTotalCount,
        }),
        slug: 'funds',
      },
      {
        title: t('group_page_tabs.projects', {
          projectsCount: isFundedProjectsLoading ? '...' : totalProjectsCount,
        }),
        slug: 'projects',
      },
      {
        title: t('group_page_tabs.project_updates', {
          projectUpdatesCount:
            isFundedProjectsLoading || isProjectUpdatesLoading ? '...' : totalProjectUpdatesCount,
        }),
        slug: 'updates',
      },
    ],
    [
      totalProjectsCount,
      isFundedProjectsLoading,
      isFundsListLoading,
      isProjectUpdatesLoading,
      totalProjectUpdatesCount,
      fundsListTotalCount,
      t,
    ],
  );

  // Set tab based on router and update modal state
  let currentTab = useGetCurrentTab(params.funderProfileSlug?.[0], tabs);

  const setTab = useCallback(
    (tabNumber: number) => {
      return router.push(`/funder-profile/${organizationId}/${tabs[tabNumber].slug}`, undefined, {
        shallow: true,
      });
    },
    [organizationId, tabs],
  );

  const {
    name,
    avatar_thumbnail,
    description,
    type,
    charity_or_company_number,
    set_up_year,
    legal_structure,
    paid_staff_number,
    volunteers_number,
    member_name_1,
    member_email_1,
    member_name_2,
    member_email_2,
    categories,
    location,
    city,
    phone_number,
    address1,
    address2,
    address3,
    county,
    postcode,
    website_url,
    facebook_url,
    linkedin_url,
    instagram_url,
    twitter_url,
    total_beneficiaries: totalBeneficiaries,
    total_funds_amount: totalAmount,
    primary_contact,
  } = data || initialFunderProfileData;

  return (
    <ProfilePage
      isFunder
      name={name}
      avatar={avatar_thumbnail}
      tabs={tabs}
      currentTab={currentTab}
      setCurrentTab={setTab}
      canModifyOrganizationProfile={Boolean(userData?.can_modify_organization_profile)}
      aboutSection={{
        description: description,
        organizationType: t(`common:funding_types.${type}`),
        organizationNumber: charity_or_company_number,
        setupYear: set_up_year,
        legalStructure:
          legal_structure &&
          t(`createAccount:create_profile_common.organization_structure.${legal_structure}`),
        staffNumber: paid_staff_number,
        volunteersNumber: volunteers_number,
        memberName1: member_name_1,
        memberEmail1: member_email_1,
        memberName2: member_name_2,
        memberEmail2: member_email_2,
        categoriesIds: categories,
        totalAmount: totalAmount,
        totalBeneficiaries: totalBeneficiaries,
      }}
      linksTile={{
        orgWebsiteUrl: website_url,
        orgFacebookUrl: facebook_url,
        orgInstagramUrl: instagram_url,
        orgTwitterUrl: twitter_url,
        orgLinkedinUrl: linkedin_url,
      }}
      locationTile={{
        coordinates: location.coordinates
          ? [Number(location.coordinates[1]), Number(location.coordinates[0])]
          : undefined,
        city: city,
        phoneNumber: phone_number,
        address1: address1,
        address2: address2,
        address3: address3,
        county: county,
        postcode: postcode,
      }}
      fundsListSection={{
        fundsListPaginatedResponse: fundsListPaginatedResponse,
      }}
      fundedProjectsSection={{
        projectsPaginatedResponse: fundedProjectsPaginatedData,
      }}
      projectUpdatesSection={{
        projectUpdatesPaginatedData: projectUpdatesPaginatedData,
      }}
      totalProjectUpdatesCount={totalProjectUpdatesCount}
      progress={userData?.progress ?? 0}
      isCurrentProfile={organizationId === userData?.organization_id}
      primaryContact={primary_contact}
    />
  );
};
