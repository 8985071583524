import { ProgressBar } from '@Components/ProgressBar/ProgressBar';
import { useTranslation } from 'react-i18next';
import styles from './FundPreviewInfo.module.scss';

export interface FundPreviewInfoProps {
  numberOfFundedProjects: number;
  totalFunds: number;
  distributedFunds: number;
}

export const FundPreviewInfo = ({
  numberOfFundedProjects,
  totalFunds,
  distributedFunds,
}: FundPreviewInfoProps) => {
  const { t } = useTranslation('fund');
  const distributedFundPercentage = (distributedFunds / totalFunds) * 100;
  return (
    <div className={styles.container}>
      <div className={styles.distributedContainer}>
        <div className={styles.fundingTextContainer}>
          <p className={styles.fundingText}>{t('preview.funds_distributed')}</p>
          <p className={styles.fundingText}>
            {t('preview.funds_distributed_amounts', {
              distributedFunds: distributedFunds,
              totalFunds: totalFunds,
            })}
          </p>
        </div>
        <ProgressBar
          value={distributedFundPercentage}
          className={styles.distributedPercentageBar}
        />
      </div>
      <div className={styles.fundedProjects}>
        {t('preview.projects_funded', { count: numberOfFundedProjects })}
      </div>
    </div>
  );
};
