import classnames from 'classnames';

import LanguageIcon from '@material-ui/icons/Language';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import { RiTwitterXFill } from 'react-icons/ri';
import { Link } from '@Components/common';

import styles from './OnlinePresenceLinks.module.scss';

interface OnlinePresenceLinksProps {
  className?: string;
  orgWebsiteUrl?: string;
  orgFacebookUrl?: string;
  orgLinkedinUrl?: string;
  orgInstagramUrl?: string;
  orgTwitterUrl?: string;
}

export const OnlinePresenceLinks: React.FC<OnlinePresenceLinksProps> = ({
  className,
  orgWebsiteUrl,
  orgFacebookUrl,
  orgLinkedinUrl,
  orgInstagramUrl,
  orgTwitterUrl,
}) => {
  const isLinksDisplayed =
    orgWebsiteUrl || orgFacebookUrl || orgLinkedinUrl || orgInstagramUrl || orgTwitterUrl;

  if (!isLinksDisplayed) return null;

  return (
    <div className={classnames(className, styles.container)}>
      {orgWebsiteUrl && (
        <Link href={orgWebsiteUrl} primary>
          <LanguageIcon />
        </Link>
      )}
      {orgFacebookUrl && (
        <Link href={orgFacebookUrl} primary>
          <FacebookIcon />
        </Link>
      )}
      {orgLinkedinUrl && (
        <Link href={orgLinkedinUrl} primary>
          <LinkedInIcon />
        </Link>
      )}
      {orgInstagramUrl && (
        <Link href={orgInstagramUrl} primary>
          <InstagramIcon />
        </Link>
      )}
      {orgTwitterUrl && (
        <Link href={orgTwitterUrl} primary>
          <RiTwitterXFill size={20} />
        </Link>
      )}
    </div>
  );
};
