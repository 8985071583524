import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  DatePicker as MaterialDatePicker,
  DatePickerProps as MaterialDatePickerProps,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { useErrorStateStyles } from '@Components/common/themeOverrides';
import styleVariables from '@Styles/_variables.module.scss';
import { DATE_FORMAT } from '@Helpers/formatDate';
import { msgFieldRequired } from '@Helpers/errorMessages';
import { formatISO } from 'date-fns';

const handleDateChange = (newDate: MaterialUiPickersDate) => {
  // Translate the Date from the datepicker into a string that react-hook-form can handle.
  return formatISO(newDate!, { representation: 'date' });
};

// We use our own onChange here for react-hook-form, so we don't expect one to be provided
// as a prop.
export interface DatePickerProps
  extends Omit<MaterialDatePickerProps, 'onChange' | 'ref' | 'value'> {
  name: string;
  required: boolean;
  defaultValue?: string;
}

export const DatePicker = (props: DatePickerProps) => {
  const methods = useFormContext();
  const { control } = methods;
  const classes = useErrorStateStyles();

  return (
    <Controller
      name={props.name}
      rules={{ required: props.required && msgFieldRequired }}
      control={control}
      defaultValue={props.defaultValue || null}
      render={({ ref, onChange, value }) => (
        <MaterialDatePicker
          inputRef={ref}
          value={value}
          inputVariant="outlined"
          format={DATE_FORMAT}
          className={props.error ? classes.errorState : classes.nonError}
          InputProps={{
            endAdornment: <DateRangeIcon style={{ color: styleVariables.colorT2Light }} />,
          }}
          onChange={(value: MaterialUiPickersDate) => {
            onChange(handleDateChange(value));
          }}
          {...props}
        />
      )}
    />
  );
};
