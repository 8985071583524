import React, { ReactElement, useCallback } from 'react';
import { Tabs as MaterialTabs, Tab as MaterialTab } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';
import styleVariables from '@Styles/_variables.module.scss';
import styles from './Tabs.module.scss';
import classnames from 'classnames';

export interface TabProps {
  title: string;
  subtitle?: string;
  value?: number;
  disabled?: boolean;
  slug?: string;
  icon?: ReactElement;
  amount?: number;
}

interface TabsProps {
  currentTab: number;
  tabs: TabProps[];
  onTabChange?: (newTab: number) => void;
  fullWidth?: boolean;
  subTab?: boolean;
  className?: string;
  primary?: boolean;
}

export const Tabs = ({ currentTab, tabs, onTabChange, subTab, className }: TabsProps) => {
  const handleChange = useCallback(
    (e, idx) => {
      if (onTabChange) {
        onTabChange(idx);
      }
    },
    [onTabChange],
  );

  const StyledTabs = withStyles(() =>
    createStyles({
      root: {
        borderBottom: `1px solid ${styleVariables.colorT2Pale}`,
        color: styleVariables.AFGraphite500,
        minHeight: '32px',
      },
      indicator: {
        backgroundColor: styleVariables.AFNavyBlue500,
        height: '3px',
        borderRadius: '1.5px',
      },
      flexContainer: {
        justifyContent: 'space-between',
        gap: '5px',
      },
    }),
  )(MaterialTabs);

  const StyledTab = withStyles(() =>
    createStyles({
      root: {
        color: styleVariables.AFGraphite500,
        textTransform: 'none',
        '&.Mui-disabled': {
          opacity: 0.25,
        },
        padding: 0,
        minHeight: 0,
        minWidth: 0,
      },
      selected: {
        color: styleVariables.AFNavyBlue500,
      },
    }),
  )(MaterialTab);

  return (
    <StyledTabs
      value={currentTab}
      onChange={handleChange}
      textColor="inherit"
      scrollButtons="auto"
      style={subTab ? { width: '70%', borderTop: 'none' } : {}}
      className={className}
    >
      {tabs.map(({ title, subtitle, value, disabled, icon, amount }, index) => (
        <StyledTab
          key={title}
          value={value || index}
          disabled={disabled}
          style={subTab ? { padding: '12px' } : {}}
          label={
            <>
              <div style={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
                {icon}
                <strong>{title}</strong>
                {amount != undefined && (
                  <span
                    className={classnames(
                      styles.amount,
                      currentTab == index && styles.amountActive,
                    )}
                  >
                    {amount}
                  </span>
                )}
              </div>

              {subtitle && <span>{subtitle}</span>}
            </>
          }
        />
      ))}
    </StyledTabs>
  );
};
